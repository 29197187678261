import { message } from 'antd';
import CryptoJS from 'crypto-js';
import api from '@/api/common';
import { UploadProps, UploadFile } from 'antd/lib/upload/interface';
import { UploadRequestOption as RcUploadRequestOption } from 'rc-upload/lib/interface';
import { BUCKET_NAME } from '@/constants/app';
//@ts-ignore
import NosUploader from './nos-js-sdk';

export type UploadRequestOption = Omit<RcUploadRequestOption, 'action'>;

type status = 'error' | 'uploading' | 'success'; // error-上传失败 uploading-正在上传 success-上传成功

export interface RcFile extends File {
  uid: string;
  readonly lastModifiedDate: Date;
  readonly webkitRelativePath: string;
}

export interface NosFileProps {
  file: RcFile;
  fileKey: string;
  fileName: string;
  progress: string;
  status: status;
  xhr: XMLHttpRequest;
}

const TRUNK_SIZE = 4 * 1024 * 1024;

const nosUpload = async (option: any) => {
  const bucketName = BUCKET_NAME;

  const { file, onProgress, onError } = option;
  const { name, size, uid } = file as RcFile;
  if (!file) return;
  const uploader = NosUploader({
    onProgress: (curFile: NosFileProps) => {
      onProgress && onProgress(+curFile.progress);
    },
    onError: (errObj: Error) => {
      message.error('文件上传失败');
      onError && onError(errObj);
    },
  });

  const getNosUrl = async (nosKey: string, bucketName: string, fileName: string) => {
    const res = await api.getNosUrl({
      nosKey,
      bucketName,
      fileName: encodeURIComponent(fileName),
      fileNameEncode: 'URL_ENCODE',
    });
    return res;
  };

  return new Promise((resolve, reject) => {
    uploader.addFile(file as RcFile, async function () {
      let nosKey = CryptoJS.MD5(name + ':' + size).toString() + `${new Date().getTime()}`; // 存储在NOS的对象名(前端生成一个唯一索引)
      console.log('nosKey', nosKey);
      const token = await api.getNosToken({ nosKey, bucketName });
      // 文件上传
      const param = {
        bucketName, // 桶名
        objectName: nosKey, // 存储在NOS的对象名(前端生成一个唯一索引)
        token, // 上传凭证
        trunkSize: TRUNK_SIZE,
      };

      uploader.upload(param, function (curFile: NosFileProps) {
        getNosUrl(nosKey, bucketName, curFile.fileName)
          .then((url) => {
            console.log('getNosUrl', url);
            resolve(url);
          })
          .catch((errObj: Error) => {
            message.error('文件上传失败');
            reject(errObj);
          });
      });
    });
  });
};

export default nosUpload;
